import React, { useState, useEffect } from "react";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import Candidats from "./../../../nav/Admin/Jobs/Candidats";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import { getJobFormatted, getUserFormatted } from "./../../../utils/formating";

const HandleUserStates = ({ user, job, users }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Candidats job={job} users={users} />;
  } else {
    return <Error403 />;
  }
};

const ModifierPage = () => {
  const [user, setUser] = useState(undefined);
  const [job, setJob] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const id = getUrlParams("id");

    getUser(setUser);
    firebase
      .firestore()
      .collection("jobs")
      .doc(id)
      .get()
      .then((doc) => {
        const tmpJob = getJobFormatted(doc);
        setJob(tmpJob);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((docs) => {
        let idx = 0;
        let newUsers = [];

        while (idx < docs.docs.length) {
          newUsers.push(getUserFormatted(docs.docs[idx]));
          idx++;
        }
        setUsers(newUsers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Candidats ayant postulé à l'offre d'emploi</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu pourras ici voir les candidats qui ont postulé à cette offre d'emploi."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,offres d'emploi,utilisateur"
        />
        <link rel="canonical" href="https://wynit.com/admin/jobs/candidates" />
      </Helmet>
      <HandleUserStates user={user} job={job} users={users} />
    </Layout>
  );
};

export default ModifierPage;
