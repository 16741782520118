function getUsedOffersFormatted(usedOffers) {
  var result = []
  var i = 0

  if (usedOffers.values && usedOffers.values.length > 0) {
    while (i < usedOffers.values.length) {
      result.push({
        id: usedOffers.values[i].mapValue.fields.offerId.stringValue,
        title: usedOffers.values[i].mapValue.fields.title.stringValue,
        description:
          usedOffers.values[i].mapValue.fields.description.stringValue,
        category: usedOffers.values[i].mapValue.fields.category.stringValue,
        date: usedOffers.values[i].mapValue.fields.date.integerValue,
        image: usedOffers.values[i].mapValue.fields.image.stringValue,
        welcomeOffer:
          usedOffers.values[i].mapValue.fields.welcomeOffer.booleanValue,
        shopId: usedOffers.values[i].mapValue.fields.shopId.stringValue,
      })
      i++
    }
  }
  return result
}

export function getUserFormatted(doc) {
  var userResult = {
    id: doc.id,
    firstName:
      doc._delegate._document.data.value.mapValue.fields.firstName.stringValue,
    lastName:
      doc._delegate._document.data.value.mapValue.fields.lastName.stringValue,
    gender:
      doc._delegate._document.data.value.mapValue.fields.gender.stringValue,
    birthDate:
      doc._delegate._document.data.value.mapValue.fields.birthDate.integerValue,
    city: doc._delegate._document.data.value.mapValue.fields.city.stringValue,
    postalCode:
      doc._delegate._document.data.value.mapValue.fields.postalCode.stringValue,
    phoneNumber:
      doc._delegate._document.data.value.mapValue.fields.phoneNumber
        .stringValue,
    subscription: {
      type:
        doc._delegate._document.data.value.mapValue.fields.subscription.mapValue
          .fields.type.stringValue,
      start:
        doc._delegate._document.data.value.mapValue.fields.subscription.mapValue
          .fields.start.integerValue,
      end:
        doc._delegate._document.data.value.mapValue.fields.subscription.mapValue
          .fields.end.integerValue,
    },
    email: doc._delegate._document.data.value.mapValue.fields.email.stringValue,
    password:
      doc._delegate._document.data.value.mapValue.fields.password.stringValue,
    usedOffers: getUsedOffersFormatted(
      doc._delegate._document.data.value.mapValue.fields.usedOffers.arrayValue
    ),
  }
  return userResult
}

function getRatesFormatted(rates) {
  var result = []
  var i = 0

  if (rates.values && rates.values.length > 0) {
    while (i < rates.values.length) {
      result.push({
        comment: rates.values[i].mapValue.fields.comment.stringValue,
        date: rates.values[i].mapValue.fields.date.integerValue,
        offerId: rates.values[i].mapValue.fields.offerId.stringValue,
        rate: rates.values[i].mapValue.fields.rate.stringValue,
        userId: rates.values[i].mapValue.fields.userId.stringValue,
      })
      i++
    }
  }
  return result
}

export function getShopFormatted(doc) {
  return {
    id: doc.id,
    name: doc._delegate._document.data.value.mapValue.fields.name.stringValue,
    description:
      doc._delegate._document.data.value.mapValue.fields.description
        .stringValue,
    category:
      doc._delegate._document.data.value.mapValue.fields.category.stringValue,
    image: doc._delegate._document.data.value.mapValue.fields.image.stringValue,
    address:
      doc._delegate._document.data.value.mapValue.fields.address.stringValue,
    latLng:
      doc._delegate._document.data.value.mapValue.fields.latLng.geoPointValue,
    rates: getRatesFormatted(
      doc._delegate._document.data.value.mapValue.fields.rates.arrayValue
    ),
    ourFavoriteIndex: Number(
      doc._delegate._document.data.value.mapValue.fields.ourFavoriteIndex
        .integerValue
    ),
  }
}

export function getOfferFormatted(doc) {
  return {
    id: doc.id,
    title: doc._delegate._document.data.value.mapValue.fields.title.stringValue,
    description:
      doc._delegate._document.data.value.mapValue.fields.description
        .stringValue,
    category:
      doc._delegate._document.data.value.mapValue.fields.category.stringValue,
    image: doc._delegate._document.data.value.mapValue.fields.image.stringValue,
    welcomeOffer:
      doc._delegate._document.data.value.mapValue.fields.welcomeOffer
        .booleanValue,
    shopId:
      doc._delegate._document.data.value.mapValue.fields.shopId.stringValue,
    isFavorite:
      doc._delegate._document.data.value.mapValue.fields.isFavorite
        .booleanValue,
    index: Number(
      doc._delegate._document.data.value.mapValue.fields.index.integerValue
    ),
    categoryIndex: Number(
      doc._delegate._document.data.value.mapValue.fields.categoryIndex
        .integerValue
    ),
    mask: doc._delegate._document.data.value.mapValue.fields.mask.booleanValue,
  }
}

function getCandidatesFormatted(candidates) {
  var result = []
  var i = 0

  if (candidates.values && candidates.values.length > 0) {
    while (i < candidates.values.length) {
      result.push({
        user: candidates.values[i].mapValue.fields.user.stringValue,
        date: candidates.values[i].mapValue.fields.date.integerValue,
      })
      i++
    }
  }
  return result
}

function getSharingFormatted(sharing) {
  var result = []
  var i = 0

  if (sharing.values && sharing.values.length > 0) {
    while (i < sharing.values.length) {
      result.push({
        user: sharing.values[i].mapValue.fields.user.stringValue,
        date: sharing.values[i].mapValue.fields.date.integerValue,
      })
      i++
    }
  }
  return result
}

export function getJobFormatted(doc) {
  return {
    id: doc.id,
    title: doc._delegate._document.data.value.mapValue.fields.title.stringValue,
    image: doc._delegate._document.data.value.mapValue.fields.image.stringValue,
    contract:
      doc._delegate._document.data.value.mapValue.fields.contract.stringValue,
    startDate:
      doc._delegate._document.data.value.mapValue.fields.startDate.stringValue,
    duration:
      doc._delegate._document.data.value.mapValue.fields.duration.stringValue,
    studiesLevel:
      doc._delegate._document.data.value.mapValue.fields.studiesLevel
        .stringValue,
    competencies:
      doc._delegate._document.data.value.mapValue.fields.competencies
        .stringValue,
    salaryRange:
      doc._delegate._document.data.value.mapValue.fields.salaryRange
        .stringValue,
    wayToApply:
      doc._delegate._document.data.value.mapValue.fields.wayToApply.stringValue,
    content:
      doc._delegate._document.data.value.mapValue.fields.content.stringValue,
    description:
      doc._delegate._document.data.value.mapValue.fields.description
        .stringValue,
    shopId:
      doc._delegate._document.data.value.mapValue.fields.shopId.stringValue,
    candidates: getCandidatesFormatted(
      doc._delegate._document.data.value.mapValue.fields.candidates.arrayValue
    ),
    sharing: getSharingFormatted(
      doc._delegate._document.data.value.mapValue.fields.sharing.arrayValue
    ),
    mask: doc._delegate._document.data.value.mapValue.fields.mask.booleanValue,
    index: Number(
      doc._delegate._document.data.value.mapValue.fields.index.integerValue
    ),
  }
}
