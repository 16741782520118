export function convertDateToTimestamp(date) {
  var timestamp = new Date(date).getTime()
  return timestamp
}

export function convertTimestampToDate(timestamp) {
  var date = new Date(timestamp)
  return date
}

export function calculateAge(timestamp) {
  var birth = new Date(timestamp) 
  var diff_ms = Date.now() - birth.getTime();
  var age_dt = new Date(diff_ms); 

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}