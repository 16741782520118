import React, { useState, useEffect } from "react"
import styled from "styled-components"

import firebase from "gatsby-plugin-firebase"

import { OrangeTitle } from "./../../../../css/Titre.module.css"
import { getUserFormatted } from "../../../../utils/formating"
import { convertTimestampToDate } from "../../../../utils/date"

const Wrapper = styled.section`
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
`

const CandidateWrapper = styled.div`
  & > p:first-child {
    font-weight: bold;
  }
`

const LineWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80vw;
  height: 1px;
  background-color: #ff8000;
`

const Candidats = ({ job, users }) => {
  return (
    <Wrapper>
      <h1 className={OrangeTitle}>Candidats</h1>
      {job.candidates.map((candidate, idx = 0) => {
        const date = convertTimestampToDate(job.candidates[idx].date / 1000)
        var idx2 = 0

        while (idx2 < users.length) {
          if (users[idx2]["id"] === candidate.user) {
            break
          }
          idx2++
        }

        if (++idx < job.candidates.length) {
          return (
            <div key={idx}>
              <p>{users[idx2]["firstName"] + " " + users[idx2]["lastName"]}</p>
              <p>{users[idx2]["gender"]}</p>
              <p>{users[idx2]["email"]}</p>
              <p>User id: {users[idx2]["id"]}</p>
              <p>
                Offre déclenchée le
                {" " +
                  date.getDate() +
                  "/" +
                  (date.getMonth() + 1).toString() +
                  "/" +
                  date.getFullYear()}
              </p>
              <LineWrapper></LineWrapper>
            </div>
          )
        } else {
          return (
            <div key={idx}>
              <p>{users[idx2]["firstName"] + " " + users[idx2]["lastName"]}</p>
              <p>{users[idx2]["gender"]}</p>
              <p>{users[idx2]["email"]}</p>
              <p>User id: {users[idx2]["id"]}</p>
              <p>
                Offre déclenchée le
                {" " +
                  date.getDate() +
                  "/" +
                  (date.getMonth() + 1).toString() +
                  "/" +
                  date.getFullYear()}
              </p>
            </div>
          )
        }
      })}
    </Wrapper>
  )
}

export default Candidats
